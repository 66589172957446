<script setup lang="ts">
defineEmits<{
  (e: 'back'): void
}>()

const hoveredBackgroundColor = COLOR.gray5
</script>

<template>
  <header class="simple-header">
    <nav class="navigation">
      <div>
        <a class="navbar-item" @click="$emit('back')">
          <ArrowBackIcon />
        </a>
      </div>

      <div>
        <slot name="right" />
      </div>
    </nav>
  </header>
</template>

<style scoped lang="scss">
.simple-header {
  box-shadow: 0 2px 0 0 hsl(0, 0%, 96%);
  padding: 0.5rem;

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    .navbar-item {
      &:hover {
        background-color: v-bind(hoveredBackgroundColor);
      }
    }
  }
}
</style>
