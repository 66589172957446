<script setup lang="ts">
import Icon from '~/assets/images/icons/arrow_back.svg?component'

type Size = 36

type Props = {
  size?: Size
}

const props = withDefaults(defineProps<Props>(), {
  size: 36,
})
</script>

<template>
  <Icon :height="props.size" :width="props.size" />
</template>
